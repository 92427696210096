'use strict';

angular.module('cpformplastApp.deliveries')
  .config(function($stateProvider) {
    $stateProvider
      .state('deliveries/delivery-management/delivery-creation', {
        url: '/deliveries/delivery-management/delivery-creation/:deliveryId',
        templateUrl: 'app/deliveries/delivery-management/delivery-creation/delivery-creation.html',
        controller: 'DeliveryCreationController',
        controllerAs: 'deliveryCreationController',
        authenticate: ['admin', 'management_dept', 'logistic_dept', 'production_dept', "receptionist"]
      })
      .state('deliveries/delivery-management', {
        url: '/deliveries/delivery-management',
        templateUrl: 'app/deliveries/delivery-management/delivery-management.html',
        controller: 'DeliveryManagementController',
        controllerAs: 'deliveryManagementController',
        authenticate: ['admin', 'management_dept', 'logistic_dept', 'production_dept', "receptionist"]
      })
      .state('deliveries/delivery-history', {
        url: '/deliveries/delivery-history',
        templateUrl: 'app/deliveries/delivery-history/delivery-history.html',
        controller: 'DeliveryHistoryController',
        controllerAs: 'deliveryHistoryController',
        authenticate: ['admin', 'management_dept','logistic_dept', 'production_dept', "receptionist"]
      });
  });
